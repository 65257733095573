import {environment} from "../environments/environment";
export class AppConstants {
  public static API = {
    LOGIN: environment.BASE_URL + '/auth',
    ITEMS: environment.BASE_URL + '/items',
    ITEMS_BY_COMPANY: environment.BASE_URL + '/items/venue/',
    ADDONS_BY_COMPANY: environment.BASE_URL + '/addons/venue/',
    ADDONS: environment.BASE_URL + '/addons/',
    CREATE_ADDONS: environment.BASE_URL + '/addons/create',
    FILES_BY_COMPANY: environment.BASE_URL + '/files/venue/',
    FILES_UPLOAD: environment.BASE_URL + '/files/upload',
    MENU_COMPANY: environment.BASE_URL + '/menus/venue/',
    ME: environment.BASE_URL + '/users/me',
    MY_COMPANIES: environment.BASE_URL + '/venues/me',
    COMPANY_REPORTS: environment.BASE_URL + '/venues/reports',
    CREATE_MENU: environment.BASE_URL + '/menus/',
    GET_COMPANY_USERS: environment.BASE_URL + '/users/venue/',
    PERMISSIONS: environment.BASE_URL + '/permissions/',
    COMPANIES: environment.BASE_URL + '/venues/',
    GET_COMPANY_MENU_TEMPLATES: environment.BASE_URL + '/menuTemplates/venue/',
    GET_SECTIONS_BY_COMPANY: environment.BASE_URL + '/sections/venue/',
    SECTIONS: environment.BASE_URL + '/sections/',
    MENU_TEMPLATES: environment.BASE_URL + '/menuTemplates/',
    MENUS: environment.BASE_URL + '/menus/',
    USERS: environment.BASE_URL + '/users/',
    REMOVE_USER_FROM_COMPANY: environment.BASE_URL + '/venues/user/delete',
    ADD_USER_TO_COMPANY: environment.BASE_URL + '/venues/user',
    MENU_BY_DATE: environment.BASE_URL + '/menus/date/',
    GET_MY_GROUPS: environment.BASE_URL + '/venue-groups/my',
    GROUPS: environment.BASE_URL + '/venue-groups/',
    VENUES: environment.BASE_URL + '/venues',
    ORDERS: environment.BASE_URL + '/orders/',

  }
}
