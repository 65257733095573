import { Injectable } from '@angular/core';
import {FormGroup, ValidationErrors} from "@angular/forms";
import {Toast, ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private toastr: ToastrService) { }

  presentToast(text: string, type: number = 2, title?: string) {
    if (type === 1){
      this.toastr.success(text, title);
    } else if (type === 0){
      this.toastr.error(text, title);
    } else {
      this.toastr.show(text, title);
    }
  }


  public getColoredChip(letter: string): any {
    letter = letter ? letter.charAt(0).toUpperCase() : '0';
    if (letter === 'A' || letter === 'C') {
      return {letter, primary: '#181c32', secondary: '#d1d3e0'};
    } else if (letter === 'B' || letter === 'D') {
      return {letter, primary: '#f64e60', secondary: '#ffe2e5'};
    } else if (letter === 'E' || letter === 'G') {
      return {letter, primary: '#ffa800', secondary: '#fff4de'};
    } else if (letter === 'F' || letter === 'H') {
      return {letter, primary: '#3699ff', secondary: '#e1f0ff'};
    } else if (letter === 'I' || letter === 'K') {
      return {letter, primary: '#85603f', secondary: '#d8ac9c'};
    } else if (letter === 'J' || letter === 'L') {
      return {letter, primary: '#1bc5bd', secondary: '#c9f7f5'};
    } else if (letter === 'M' || letter === 'O') {
      return {letter, primary: '#fed049', secondary: '#fdf1d6'};
    } else if (letter === 'N' || letter === 'P') {
      return {letter, primary: '#7eca9c', secondary: '#ccffbd'};
    } else if (letter === 'Q' || letter === 'S') {
      return {letter, primary: '#8950fc', secondary: '#eee5ff'};
    } else if (letter === 'R' || letter === 'T') {
      return {letter, primary: '#536162', secondary: '#f3f4ed'};
    } else if (letter === 'U' || letter === 'W') {
      return {letter, primary: '#1597bb', secondary: '#8fd6e1'};
    } else if (letter === 'V' || letter === 'X') {
      return {letter, primary: '#93329e', secondary: '#f8a1d1'};
    } else if (letter === 'Y' || letter === 'Z') {
      return {letter, primary: '#30e3ca', secondary: '#e4f9f5'};
    } else {
      return {letter, primary: '#3d84a8', secondary: '#bad7df'};
    }
  }

  public getFormValidationErrors(form: FormGroup) {
    Object.keys(form.controls).forEach(key => {
      // @ts-ignore
      const controlErrors: ValidationErrors = form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }


  public getStatus(status: string): any {
    status = status.toUpperCase();
    if (status === 'CANCELLED' || status === 'CANCELLED_BY_CUSTOMER' || status === 'FAILED') {
      return {status, primary: '#f64e60', secondary: '#ffe2e5'};
    } else if (status === 'PENDING') {
      return {status, primary: '#ffa800', secondary: '#fff4de'};
    } else if (status === 'PREPARING') {
      return {status, primary: '#3699ff', secondary: '#e1f0ff'};
    } else if (status === 'READY') {
      return {status, primary: '#1bc5bd', secondary: '#c9f7f5'};
    } else if (status === 'IN TRANSIT') {
      return {status, primary: '#536162', secondary: '#f3f4ed'};
    } else if (status === 'CANCELLED') {
      return {status, primary: '#9d0808', secondary: '#ff6c6c'};
    } else if (status === 'ACCEPTED') {
      return {status, primary: '#1bc5bd', secondary: '#c9f7f5'};
    } else if (status === 'REJECTED') {
      return {status, primary: '#93329e', secondary: '#f8a1d1'};
    } else if (status === 'COMPLETED') {
      return {status, primary: '#1fab89', secondary: '#9df3c4'};
    } else {
      return {status, primary: '#3d84a8', secondary: '#bad7df'};
    }
  }


  public hexToRgbA(hex: string){
    // @ts-ignore
    const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
    const rgba = `rgba(${r},${g},${b},1)`
    return {
      r,g,b,rgba
    }
  }

}
