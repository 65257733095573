import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  orders = [];
  loading = false;
  pages = {
    currentPage: 0,
    totalPage: 1
  }

  constructor() { }

  ngOnInit(): void {
  }

  getPendingOrders(){

  }

  loadMore() {

  }
}
