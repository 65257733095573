<div *ngIf="valid">{{msg}}</div>
<div *ngIf="min">{{msg}}</div>
<div *ngIf="max">{{msg}}</div>
<div *ngIf="required">{{msg}}</div>

<div *ngIf="form.controls[field].invalid && (form.controls[field].dirty || form.controls[field].touched) && form.controls[field].errors" class="error-msg">

  <div *ngIf="form.controls[field].errors['minlength'] && length === 0">
    {{msg.min && msg.min && msg.min.length > 0 ? msg.min : 'Please enter at least ' + form.controls[field].errors["minlength"].requiredLength + ' characters'}}
  </div>

  <div *ngIf="form.controls[field].errors['maxlength'] && length === 0 ">
    {{msg.max && msg.max && msg.max.length > 0 ? msg.min : 'Maximum length is allowed ' + form.controls[field].errors["minlength"].requiredLength + ' characters'}}
  </div>

  <div *ngIf="form.controls[field].errors['email']">
    {{msg.valid ? msg.valid : 'Please enter a valid email'}}
  </div>

  <div *ngIf="form.controls[field].errors.required">
    {{msg.required ? msg.required : 'This is required field.'}}
  </div>

  <div *ngIf="(!form.controls[field].errors.required || form.controls[field].errors['mustMatch']) && matchWith && matchWith.length > 0">
    <span *ngIf="msg.valid">{{msg.valid}}</span>
    <span *ngIf="!msg.valid">{{name}} Does not match with {{matchWith}}</span>
  </div>

  <div *ngIf="!form.controls[field].errors.required && length > 0">
    {{name}} should be {{length}} digits.
  </div>


</div>
