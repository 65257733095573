import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SessionService} from "./session.service";
import {Observable} from "rxjs";
import {AppConstants} from "../AppConstants";
import {LoginRes} from "../models/LoginRes";
import {Item} from "../models/Item";
import {File} from '../models/file'
import {AddOnGroup} from "../models/AddOnItem";
import {Menu} from "../models/Menu";
import {User} from "../models/User";
import {Permission} from "../models/Permission";
import {MenuTemplate} from "../models/MenuTemplate";
import {Section} from "../models/Section";
import {Venue} from "../models/Venue";
import {VenueGroup} from "../models/VenueGroup";
import {Order} from "../models/Order";

@Injectable({
  providedIn: 'root'

})
export class ApiService {

  private headers: any;
  private userId = '';
  constructor(private http: HttpClient, private session: SessionService) {
    // if user is logged in it will set headers automatically to headers variable
    // it also observes the changes, so it will automatically handle everything
    this.session.auth.subscribe(data => {
      if (data) {
        // if (session.getToken())
        this.headers = { Authorization: `Bearer ${session.getToken()}` };
        this.userId = session.getUser().id;
        // console.log('UserId Assigned', this.userId, session.getUser());
      } else {
        this.headers = {};
      }
    });
  }

  public setToken(): void {
    this.headers = { Authorization: `Bearer ${this.session.getToken()}` };
  }

  doLogin(email: string, password: string): Observable<LoginRes> {
    const auth = {
      Authorization: 'Basic ' + btoa(`${email}:${password}`)
    };

    return this.http.post<LoginRes>(AppConstants.API.LOGIN, {}, { headers: auth });
  }

  getItemsByCompany(venue: string): Observable<Item[]>{
    return this.http.get<Item[]>(AppConstants.API.ITEMS_BY_COMPANY + venue, {headers: this.headers});
  }

  getMyVenues(): Observable<Venue[]>{
    return this.http.get<Venue[]>(AppConstants.API.MY_COMPANIES, {headers: this.headers});
  }

  getAssetsByCompany(venue: string): Observable<File[]> {
    return this.http.get<File[]>(AppConstants.API.FILES_BY_COMPANY + venue, {headers: this.headers});
  }

  uploadImage(form: any): Observable<File>{
    return this.http.post<File>(AppConstants.API.FILES_UPLOAD, form, {headers: this.headers})
  }

  checkResourceAccessible(url: string){
    return this.http.get(url);
  }

  addAddons(addons: any): Observable<AddOnGroup[]>{
    return this.http.post<AddOnGroup[]>(AppConstants.API.CREATE_ADDONS, addons, {headers: this.headers});
  }

  addAddon(addon: any, updateId?: string): Observable<AddOnGroup[]>{
    if (updateId){
      return this.http.put<AddOnGroup[]>(AppConstants.API.ADDONS + updateId, addon, {headers: this.headers});
    } else {
      return this.http.post<AddOnGroup[]>(AppConstants.API.CREATE_ADDONS, addon, {headers: this.headers});
    }
  }

  addItem(item: any, id?: string): Observable<Item>{
    if (!!id){
      return this.http.put<Item>(AppConstants.API.ITEMS + '/' + id, item, {headers: this.headers});
    } else {
      return this.http.post<Item>(AppConstants.API.ITEMS, item, {headers: this.headers});
    }
  }

  deleteItem(id: any): Observable<Item>{
    return this.http.delete<Item>(AppConstants.API.ITEMS + '/' + id, {headers: this.headers});
  }

  getAddonsByCompany(venue: string): Observable<AddOnGroup[]>{
    return this.http.get<AddOnGroup[]>(AppConstants.API.ADDONS_BY_COMPANY + venue, {headers: this.headers});
  }

  deleteAddon(id: string): Observable<AddOnGroup[]>{
    return this.http.delete<AddOnGroup[]>(AppConstants.API.ADDONS + id, {headers: this.headers});
  }

  getMenus(venue: string, date: String): Observable<Menu[]> {
    return this.http.post<Menu[]>(AppConstants.API.MENU_BY_DATE + venue, {start: date},{headers: this.headers});
  }

  createMenu(body: any) {
    return this.http.post(AppConstants.API.CREATE_MENU, body, {headers: this.headers})
  }

  deleteMenu(id: string) {
    return this.http.delete(AppConstants.API.CREATE_MENU + id, {headers: this.headers})
  }

  markAvailable(id: string | undefined, available: boolean) {
    return this.http.put(AppConstants.API.CREATE_MENU  + 'available/' + id, {available}, {headers: this.headers})
  }

  getUsersByCompany(id: string): Observable<User[]> {
    return this.http.get<User[]>(AppConstants.API.GET_COMPANY_USERS + id, {headers: this.headers})
  }

  changePermissions(permission: Permission) {
    return this.http.put<Permission>(AppConstants.API.PERMISSIONS + permission.id, permission, {headers: this.headers})
  }

  changePassword(password: string) {
    return this.http.put<Permission>(AppConstants.API.USERS + this.session.getUser().id, {password}, {headers: this.headers})
  }

  updateProfile(body: any) {
    return this.http.put<Permission>(AppConstants.API.USERS + this.session.getUser().id, body, {headers: this.headers})
  }

  updateCompanyDetails(venue: Venue) {
    console.log(venue.id);
    return this.http.put<Venue>(AppConstants.API.COMPANIES + venue.id, venue, {headers: this.headers})
  }

  getMenuTemplates(venue: Venue): Observable<MenuTemplate[]> {
    return this.http.get<MenuTemplate[]>(AppConstants.API.GET_COMPANY_MENU_TEMPLATES + venue.id, {headers: this.headers})
  }

  createMenuTemplate(body: any): Observable<MenuTemplate> {
    return this.http.post<MenuTemplate>(AppConstants.API.MENU_TEMPLATES, body,{headers: this.headers})
  }

  getSectionsByCompany(venue: Venue): Observable<Section[]> {
    console.log(AppConstants.API.GET_SECTIONS_BY_COMPANY + venue.id)
    return this.http.get<Section[]>(AppConstants.API.GET_SECTIONS_BY_COMPANY + venue.id, {headers: this.headers})
  }

  createMenuSection(body: any): Observable<MenuTemplate> {
    return this.http.post<MenuTemplate>(AppConstants.API.SECTIONS, body,{headers: this.headers})
  }

  deleteMenuTemplate(id: string): Observable<MenuTemplate> {
    return this.http.delete<MenuTemplate>(AppConstants.API.MENU_TEMPLATES + id,{headers: this.headers})
  }

  updateMenuTemplate(id: string, body: any): Observable<MenuTemplate> {
    return this.http.put<MenuTemplate>(AppConstants.API.MENU_TEMPLATES + id, body,{headers: this.headers})
  }

  updateMenu(menu: Menu): Observable<MenuTemplate> {
    return this.http.put<MenuTemplate>(AppConstants.API.MENUS + menu.id, menu,{headers: this.headers})
  }

  updateMenuSection(id: string, body: any): Observable<MenuTemplate> {
    return this.http.put<MenuTemplate>(AppConstants.API.SECTIONS + id, body,{headers: this.headers})
  }

  deleteSection(id: string): Observable<MenuTemplate> {
    return this.http.delete<MenuTemplate>(AppConstants.API.SECTIONS + id,{headers: this.headers})
  }

  getReports(start: string, end: string, venue: string, type: string) {
    return this.http.post<any>(AppConstants.API.COMPANY_REPORTS + venue,{start, end, venue, type}, {headers: this.headers})
  }

  getAllUsers() {
    return this.http.get<User[]>(AppConstants.API.USERS, {headers: this.headers});
  }

  createUser(body: any) {
    return this.http.post<User>(AppConstants.API.USERS, body, {headers: this.headers});
  }

  deleteUser(id: string) {
    return this.http.delete<User>(AppConstants.API.USERS + id, {headers: this.headers});
  }

  removeUserFromCompany(id: string, venue?: string) {
    return this.http.post<User>(AppConstants.API.REMOVE_USER_FROM_COMPANY, {id, venue}, {headers: this.headers});
  }

  addCompanyToUser(venue: string, id: string) {
    return this.http.post<User>(AppConstants.API.ADD_USER_TO_COMPANY, {id, venue}, {headers: this.headers});
  }

  getGroups(): Observable<VenueGroup[]> {
    return this.http.get<VenueGroup[]>(AppConstants.API.GET_MY_GROUPS, {headers: this.headers})
  }

  getAllVenues(): Observable<Venue[]> {
    return this.http.get<Venue[]>(AppConstants.API.VENUES, {headers: this.headers})
  }

  deleteGroup(id: string): Observable<any> {
    return this.http.delete<any>(AppConstants.API.GET_MY_GROUPS + id, {headers: this.headers})
  }

  updateGroup(body: any, id: string): Observable<VenueGroup> {
    return this.http.put<VenueGroup>(AppConstants.API.GROUPS + id, body,{headers: this.headers})
  }

  addGroup(body: any) {
    return this.http.post<VenueGroup>(AppConstants.API.GROUPS, body,{headers: this.headers})
  }

  getOrdersByStatus(status: string[]): Observable<Order[]> {
    return this.http.get<Order[]>(AppConstants.API.ORDERS + '?status=' + status.toString(), {headers: this.headers})
  }
}
