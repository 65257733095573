import { Injectable } from '@angular/core';
import {MenuItem} from "../models/MenuItem";

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  private currentSection = 0;

  constructor() { }

  changeSection(item: MenuItem){
    this.currentSection = item.section;
  }

  getCurrentSection(){
   return this.currentSection;
  }

}
