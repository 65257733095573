<div class="text-center mb-4">
  <div class="mb-5 fs-15 dark"><b>Sign in your account</b></div>
</div>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12">
      <div class="mb-3">
        <label class="form-label" for="email"><strong class="form_label">Email</strong></label>
        <input aria-describedby="email" class="input-grey" id="email" type="email" formControlName="email">
        <app-input-error [form]="loginForm" field="email" [msg]="errors.email"></app-input-error>
      </div>

      <div class="col-12">
        <div class="mb-4" style="margin-top: 35px;">
          <label class="form-label d-flex justify-content-between" for="password"><strong class="form_label">Password</strong></label>
          <input class="input-grey mb-3" id="password" type="password" formControlName="password">
          <app-input-error [form]="loginForm" field="password" [msg]="errors.password"></app-input-error>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="mb-4 fs-12"><a class="h-link" routerLink="/auth/forgot-password"><b>Forgot Password?</b></a></div>
      <button mat-raised-button class="btn-blue-full" type="submit">Sign Me In</button>
    </div>
  </div>
</form>
