import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DataShareService} from "./data-share.service";
import {AppConstants} from "../AppConstants";
import {Venue} from "../models/Venue";
import * as _ from 'lodash';

@Injectable({
  providedIn: `root`,
})

/**
 * This service is used to keep the things related to Auth,
 * so user info, token and things like that can be kept here
 */
export class SessionService {
  notification: any = {
    orders : []
  }
  newOrderNotification = new BehaviorSubject<any>(0)
  orderUpdate = new BehaviorSubject<any>(0)
  public isLoggedIn = false;
  public companies: Venue[] = [];
  // public auth = new BehaviorSubject<boolean>(null);
  public auth = new ReplaySubject(1);
  public company: Venue | undefined;
  public companyNotFound = false;
  public companyLoaded = new BehaviorSubject<boolean>(false)
  public companyChange: BehaviorSubject<any> = new BehaviorSubject(null)
  private token = '';
  private user: any;
  private loading: boolean = false;
  pendingCount = 0;

  constructor(private router: Router, private storage: LocalStorageService,
              private http: HttpClient, public data: DataShareService) {
    // Get all info from localstorage
    this.init();
  }

  /**
   * To be used to set token.
   * @param token - The token post received post login.
   */
  async setToken(token: string): Promise<void> {
    this.token = token;
    await this.storage.setItem('token', token);
    await this.storage.setBoolean('loggedIn', true);
    this.getCompanies();
  }

  /**
   * To be used to set user.
   * @param user - The user object.
   */
  async setUser(user: any): Promise<void> {
    // console.log('setting user **************', user);
    this.user = user;
    this.isLoggedIn = true;
    await this.storage.setObject('user', user);
    this.auth.next(true);
    // console.warn('Got this for saving ******************', user);
  }

  /** This function can be used to get token */
  getToken(): string {
    return this.token;
  }

  /** This function can be used to get user */
  getUser(): any {
    return this.user;
  }

  /** This function can be used to get company */
  getCompany(): Venue {
    return <Venue>this.company;
  }

  /** Logout current user */
  logout(): void {
    this.isLoggedIn = false;
    this.storage.removeItem('user');
    this.storage.removeItem('token');
    this.storage.setBoolean('loggedIn', false);
    this.token = '';
    this.auth.next(false);
    this.router.navigateByUrl('/auth/login');
  }

  /** This function is private and should not be used for anything else than init of session service */
  private init(): void {
    this.isLoggedIn = this.storage.getBoolean('loggedIn');
    this.user = this.storage.getObject('user');
    this.token = this.storage.getItem('token') || '';
    if (this.token)
      this.verifyToken();
    this.auth.next(this.isLoggedIn);
    // We can also optionally call refresh token API is available to refresh the token
  }

  /**
   * This function will check on every reload if saved token is still valid and active
   * @private
   */
  private verifyToken() {
    const headers = {Authorization: `Bearer ${this.token}`};
    this.http.get<any>(AppConstants.API.ME, {headers}).subscribe(data => {
      // console.log('user data', data);
      if (data) {
        this.setUser(data);
        this.getCompanies();
      } else {
        this.logout();
        this.companyNotFound = true;
      }
      // console.log('Token verified :: Session is still active!');
    }, error => {
      this.logout();
      this.companyNotFound = true;
      console.log('Session is expired!', error.status);
    });
  }

  public reloadSession(){
    this.verifyToken();
  }

  async getCompanies() {
    const headers = {Authorization: `Bearer ${this.token}`};
    this.http.get<Venue[]>(AppConstants.API.MY_COMPANIES, {headers}).subscribe((data) => {
      if (data.length > 0){
        this.companies = data;
        const id = localStorage.getItem('company')
        if (id){
          this.company = _.find(this.companies, item => item.id === id);
        }
        if (!this.company){
          this.company = this.companies[0];
        }
      }
      else{
        this.companyNotFound = true;
      }
      this.companyLoaded.next(true);
    });
  }

  public switchVenue(company: Venue){
    this.company = company;
    this.companyChange.next(company);
    localStorage.setItem('company', company.id)
    window.location.reload();
  }
}
