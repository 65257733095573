import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {FormBuilder, Validators} from "@angular/forms";
import {DataShareService} from "../../../services/data-share.service";
import {SessionService} from "../../../services/session.service";
import {UtilService} from "../../../services/util.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]]
  });

  errors = {
    email : {valid : 'Please enter a valid email', required: 'Please enter an email'},
    password : {valid : 'Please enter a valid password', required: 'Please enter a password'},
  };

  constructor(private fb: FormBuilder, private api: ApiService, public data: DataShareService,
              public session: SessionService, public util: UtilService, private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit(): any{
    if (this.loginForm.valid){
      this.api.doLogin(this.loginForm.value.email || '', this.loginForm.value.password || '').subscribe(async data => {
        await this.session.setUser(data.user);
        await this.session.setToken(data.token);
        this.session.companyLoaded.subscribe(data => {
          if (data){
            this.router.navigateByUrl('/pages')
          }
        })
        await this.api.setToken();
      }, error => {
        this.util.presentToast('Unable to login, please check id and password!', 0);
      });
    }
  }
}
